import React, {useRef, useEffect} from 'react'
import { YMaps, Map, Placemark, ZoomControl, useYMaps } from '@pbe/react-yandex-maps';

export default function MyMap() {
    const mapRef = useRef(null);
    const ymaps = useYMaps(['Map']);


    useEffect(() => {
        if (!ymaps || !mapRef.current) {
            return;
        }
        new ymaps.Map(mapRef.current, {
            center: [50.637438, 36.753159],
            zoom: 15,
            scrollZoom: false
        })

    }, [ymaps]);

    return(

        <>
            {/*<iframe*/}
            {/*    src="https://yandex.ru/map-widget/v1/?um=constructor%3Aa9992167038aa82b37100d0dafa41b7c95fb45125f852ebb7d859224e36b27f3&amp;source=constructor"*/}
            {/*    width="100%" height="640" frameBorder="0"></iframe>*/}
            {/*<YMaps>*/}
            {/*    <div>*/}
            {/*        <Map defaultState={{ center: [50.637438, 36.753159], zoom: 15 }} width={'100%'} height={'640rem'} > </Map>*/}
            {/*    </div>*/}
            {/*</YMaps>*/}

                {/*<div ref={mapRef} style={{ width: '100%', height: '640rem' }} />*/}

            <Map defaultState={{ center: [50.633774, 36.750766], zoom: 15,}}
                 instanceRef={ref => { ref && ref.behaviors.disable('scrollZoom'); }}
                 width={'100%'}
                 height={'640rem'}
            > <Placemark  geometry={[50.633774, 36.750766]} />
                <ZoomControl />
            </Map>*/}

        </>
    )
}

