import React, { useState } from "react";

import LogoUrl from "./img/logo.svg"
import BIKUrl from "./img/BIK.svg"
import PlanUrl from "./img/white-plan.jpg"
import {onPhoneInput} from "./mask";
import MyMap from "./Map";
import { YMaps } from '@pbe/react-yandex-maps';

function Benefit (props) {
    return (
        <>
            <div className='benefit-card'>
                <span className={`benefit-card_icon ${props.img}`}></span>
                <p className='benefit-card_title'>{props.title}</p>
                <p className='benefit-card_text'>{props.text}</p>
            </div>
        </>
    )
}

function Form (){
    const [value, setValue] = useState('')
    const [successForm, setSuccessForm] = useState(false)

    const submitForm = (event) =>{
        event.preventDefault()
        const formData = new FormData(event.target)
        fetch('https://bik31.ru/mail-send/',  {
            method:  'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok){
                    throw Error('Ошибка: ' + response.status + '. Попробуйте позже.')
                }
                return response.json()
            })
            .then(data => {
                console.log('Success')
                setSuccessForm(true)

            })
            .catch(error => {
                console.log('error')
            })
    }
    return (
        <>
            {!successForm ?
                <>
                    <p className='form-title'>Получите консультацию</p>
                    {/*<p className='form-subtitle mb-24'> и узнайте подробности запуска продаж</p>*/}
                    <form action="" method="post" onSubmit={(e) => submitForm(e)} className='consultation-form'>
                        <input className='phone-input' value={value} name='phone' placeholder="+7" type="tel" onChange={e => onPhoneInput(e, setValue)}/>
                        <input type="submit" id="submit" className="d-none" value="Отправить"/>
                        <label htmlFor="submit" className="send-form_btn">Отправить</label>
                    </form>
                    <p className='agreement-text'>Нажимая «Отправить» вы соглашаетесь с <a href='https://bik31.ru/polzovatelskoe-soglashenie/' className='personal-data-link bold'>Политикой обработки персональных данных</a></p>
                </>
                :
                <div className='success-message'>
                    <p className='success-message_title'>Заявка отправлена</p>
                    <p className='success-message_subtitle'>менеджер скоро свяжется с вами по указанному номеру</p>
                </div>
            }
        </>
    )
}




function App() {

    return (
        <>
            <div className="preview-block">
                <div className="container_center">
                    <div className="preview-block_header">
                        <img className='logo-img' src={LogoUrl} alt="Белый луг"/>
                        <div className='header-contacts'>
                            <a href="tel:88003012931" className="phone">8 800 301 29 31</a>
                            <a href="#consultation" className="consult-btn">Консультация</a>
                        </div>
                    </div>
                    <div className='preview-block_content'>
                        <h3 className='preview-block_top-text'>Новый микрорайон</h3>
                        <h1>Белый луг</h1>
                        <h2 className='preview-block_subtitle'>продажа участков от 663 000 ₽*</h2>
                        {/*<div className='review-block_border-rows'>*/}
                        {/*    <p className='preview-block_text'>Подключение коммуникаций уже включено в стоимость*</p>*/}
                        {/*</div>*/}
                        <div className='d-flex location-block'>
                            <p className='location _first'>10 км от центра Белгорода</p>
                            <p className='location'>продолжение Севрюково 62.25</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-content'>
                <div className="container_center">
                    <div className='benefit-grid'>
                        <Benefit title={'Без производств поблизости'} text={'Чистый воздух и красота природы позволят спокойно отходнуть и набраться сил'} img={'_factory'}/>
                        <Benefit title={'Природная зона'} text={'В 15-ти минутах пешком находится урочище с пешеходными тропами и небольшим прудом'} img={'_park'}/>
                        <Benefit title={'Общественный транспорт'} text={'Регулярный автобус №227 и транспортная доступность — 15 минут до центра города'} img={'_bus'}/>
                    </div>
                    <p className='gray-text mb-0'>*первоначальный взнос за участок от 397 800 ₽</p>
                    <p className='gray-text'>Подключение коммуникаций уже включено в стоимость, к 2026 году микрорайон будет полностью обеспечен сетями водоснабжения и водоотведения</p>
                    <div className='text-img-grid'>
                        <div className='text-block'>
                            <p className='page-title mb-16'>Новое поле для жизни</p>
                            <p className='page-text pb-16 mb-16'>«Белый Луг» — новый микрорайон в Белгородском районе, продолжающий развитие Севрюково 62.25. Он расположен в 10 км от центра города, предлагая сочетание городской жизни и природы. Благодаря отсутствию промзон и близости к природе, жители могут наслаждаться свежим воздухом и активным отдыхом рядом с домом. С 2026 года планируется полное обеспечение водоснабжением и водоотведением, уже сейчас доступна инфраструктура Севрюково 62.25. До центра города — всего 15 минут пути и доступен общественный транспорт. В этом сезоне будет организовано грейдированние дороги для облегчения процесса застройки участков первой очереди.</p>
                            <a className='btn _download' href={PlanUrl} download>Скачать план микрорайона</a>
                        </div>
                        <div className='img-block'></div>
                    </div>
                    <p className='page-title _green mb-24'>Природа микрорайона</p>
                    <div className='gallery-grid'>
                        <div className='gallery-img _1'></div>
                        <div className='gallery-img _2'></div>
                        <div className='gallery-img _3'></div>
                    </div>
                    <div className='booking' id='consultation'>
                        <p className='booking-title mb-16'>Самостоятельное бронирование участков</p>
                        <a href='https://bik31.ru/buy-land/uchastki-izhs/' className='booking-subtitle'>Бронируйте уже сейчас <span className='bold'>на сайте БИК</span></a>
                    </div>
                    <div className='form-block'>

                        <Form />

                    </div>
                </div>
            </div>
            <div className='yandex-map'>
                <YMaps>
                    <MyMap/>
                </YMaps>
            </div>


            <div className='footer'>
                <div className='top'>
                    <div className='container_center'>
                        <div className='block-content'>
                            <div className='d-flex footer-logo'>
                                <img src={BIKUrl} alt="БИК"/>
                                <p className='bik-text'>Белгородская ипотечная корпорация</p>
                            </div>
                            <div className='footer-contacts'>
                                <a href="tel:88003012931" className="phone">8 800 301 29 31</a>
                                <a className='bik-link' href="https://bik31.ru/">На сайт БИК</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='bottom'>
                    <div className='container_center'>
                        <p>© Официальный сайт АО «БИК» и ГУП «Фонд поддержки ИЖС» Белгородской области. Все права защищены. Все материалы сайта доступны по лицензии Creative Commons Attribution 4.0 при условии ссылки на первоисточник.</p>
                    </div>
                </div>

            </div>
        </>
    );
}

export default App;
