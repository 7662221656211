const getInputNumbersValue = function(input) {
    return input.value.replace(/\D/g, "")
}
const getFormattedPhoneInputValue = function(numbers) {
    let inputNumbersValue = numbers
    let formattedInputValue = ""

        // russian number
        if (inputNumbersValue[0] !== "7")
            inputNumbersValue = "7" + inputNumbersValue

        let firstSymbols = (inputNumbersValue[0] !== "7") ? "+7" : "+7"

        formattedInputValue = firstSymbols + " "

        if (inputNumbersValue.length > 1)
            formattedInputValue += + inputNumbersValue.substring(1, 4)

        if (inputNumbersValue.length >= 5)
            formattedInputValue += " " + inputNumbersValue.substring(4, 7)

        if (inputNumbersValue.length >= 8)
            formattedInputValue += " " + inputNumbersValue.substring(7, 9)

        if (inputNumbersValue.length >= 10)
            formattedInputValue += " " + inputNumbersValue.substring(9, 11)

        return formattedInputValue

}


const onKeyDown = function (e, input, setValue) {
    console.log(getInputNumbersValue(input).length)
    console.log(e.nativeEvent.inputType)
    if (e.nativeEvent.inputType === "deleteContentBackward" && getInputNumbersValue(input).length === 1) {
        setValue('')
        console.log('--------------')
    }
}

function onPhoneInput(e, setValue) {
    const input = e.target
    let inputNumbersValue = getInputNumbersValue(input);
    const selectionStart = input.selectionStart;

    if (!inputNumbersValue)
        return input.value = ""

    if (input.value.length !== selectionStart) {
        setValue(getFormattedPhoneInputValue(inputNumbersValue))
        input.selectionStart = selectionStart
        input.selectionEnd = selectionStart
    }

    //input.addEventListener('keydown', () => onKeyDown(e, input, setValue))
    setValue(getFormattedPhoneInputValue(inputNumbersValue))
}


export {onPhoneInput}